import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "gatsby";

import "./customBreadcrumb.scss";

import homeIcon from "../../images/icons/home-icon.svg"

export default function CustomBreadcrumb({ crumbs, theme }) {
  crumbs[0].crumbLabel = (
      <img src={homeIcon} className={'home-icon'} alt={"Icon page d'accueil"}/>
  );
  if(crumbs.length > 3){
    const firstItem = crumbs[0];
    const lastItems = crumbs.slice(crumbs.length-2);
  
    crumbs = []
    crumbs.push(firstItem)
    crumbs.push(...lastItems)
  }
  return (
    <nav className="customBreadcrumb">
      <ol className="customBreadcrumb__list">
        {crumbs.map((crumb, i) => {
          return (
            <li
              key={i}
              className={`${
                theme
                  ? `customBreadcrumb__item--${theme}`
                  : "customBreadcrumb__item"
              }`}
            >
              <Link
                className={`customBreadcrumb__link
                 ${
                   i === crumbs.length - 1 && "customBreadcrumb__link--active"
                 }`}
                to={crumb.pathname}
              >
                {crumb.crumbLabel}
              </Link>
              {i !== crumbs.length - 1 && (
                <FontAwesomeIcon icon={faChevronRight} color={'#DFD9D4'} />
              )}
            </li>
          );
        })}
      </ol>
    </nav>
  );
}
