import React  from "react";
import { Helmet } from "react-helmet";

import Layout from "../../components/layout/layout";
import SEO from "../../components/seo";
import Button from "../../components/button/button";
import HeaderLight from "../../components/header/headerLight/headerLight";

import iconDownloadWhite from "../../images/icons/icon-download-white.svg";

import "./style.scss";
import iconEnvelop from "../../images/declarer-sinistre/icon-envelop.svg";
import pdfSinistre from "../../../static/pdfs/modèle_déclaration_sinistre_dommages_ouvrage.pdf"

const DeclarerSinistre = ({ location }) => {

  return (
    <>
      <Helmet>
        <body className="declarer-sinistre" />
      </Helmet>
      <Layout>
        <SEO title="Declarer un sinistre" />
        <HeaderLight
          title={
            <>
              <strong>Déclarer un sinistre dommages-ouvrage</strong>
            </>
          }
          location={location}
          crumbLabel="Declarer un sinistre"
        />

        <section className="declarer-sinistre__section_1">
          <p>
            Le formulaire de déclaration de sinistre Dommages-Ouvrage doit nous être adressé par {" "}
            <strong>courrier recommandé avec accusé de réception</strong> {" "}
            pour être considéré comme constituée.
          </p>
          <a
            href={pdfSinistre}
            className="declarer-sinistre__btn-dl"
            download
          >
            <Button
              text="Télécharger le formulaire de déclaration"
              icon={<img src={iconDownloadWhite} alt="icone download" />}
            />
          </a>
        </section>

        <section className="declarer-sinistre__section_2">
          <div>
            <img src={iconEnvelop} alt="icône envelope" />
          </div>
          <div>
            <p>
              L'Auxiliaire <br/>
              Service Indemnisation <br/>
              20 rue Garibaldi <br/>
              BP 6402 <br/>
              69413 Lyon Cedex 06
            </p>
          </div>
        </section>

        <section className="declarer-sinistre__section_3">
          <h3>Clauses-types applicables au contrat</h3>
          <p>
            La déclaration de sinistre est réputée constituée dès qu'elle comporte au minimum les renseignements suivants :
            <br/><br/>
            <li>le numéro du contrat d'assurance et, le cas échéant, celui de l'avenant,</li>
            <li>le nom du propriétaire de la construction endommagée,</li>
            <li>l'adresse de la construction endommagée,</li>
            <li>la date de réception ou, à défaut, la date de la première occupation des locaux,</li>
            <li>la date d'apparition des dommages ainsi que leur description et localisation,</li>
            <li>si la déclaration survient pendant la période de parfait achèvement au sens de l'article 1792-6 du code civil, la copie de la mise en demeure effectuée au titre de la garantie de parfait achèvement.</li>
          </p>
          <br/><br/><br/>
          <h3>Point de départ et durée de la garantie</h3>
          <p>
            Selon l’article <strong>A243-1</strong>, la période de garantie est précisée aux conditions particulières.
            <br/>Elle commence au plus tôt (sauf certaines dispositions), à l'expiration du délai de garantie de parfait achèvement défini à l'article 1792-6 du code civil.
            <br/>Elle prend fin à l'expiration d'une période de dix ans à compter de la réception.
          </p>
        </section>

      </Layout>
    </>
  )
}

export default DeclarerSinistre;
